import React from 'react';
import ReactDOM from 'react-dom';
import GraphiQL from 'graphiql';
import fetch from 'isomorphic-fetch';

import 'src/application.css';
import 'primer-css/css/primer.css';
import 'graphiql/graphiql.css';

function graphQLFetcher(graphQLParams) {
  var el = document.getElementById('graphiql');
  var token = el.getAttribute('data-token');
  var apiUrl = el.getAttribute('data-api-url');
  return fetch(apiUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
    },
    body: JSON.stringify(graphQLParams),
  }).then(response => response.json());
}

ReactDOM.render(<GraphiQL fetcher={graphQLFetcher} variables='' />, document.getElementById('graphiql-container'));
